export enum ExnatonMoleculeType {
  selfConsumptionChart = 'exnaton-self-consumption-chart',
  currentPrice = 'exnaton-current-price',
  priceChart = 'exnaton-price-chart',
  analyticsView = 'exnaton-analytics-view',
  carouselCard = 'exnaton-carousel-card',
  kpiCard = 'exnaton-kpi-card',
  financeChart = 'exnaton-finance-chart',
  comparison = 'exnaton-comparison',
  productView = 'exnaton-product-view',
  datePicker = 'exnaton-date-picker',
}

export enum ExnatonGroupType {
  analyseChart = 'analyseChart',
  detailedReport = 'exnaton-detailed-report',
}

export type ExnatonType = ExnatonMoleculeType | ExnatonGroupType;

export enum ExnatonMoleculeField {
  accountAveragePriceForConsumption = 'AccountAveragePriceForConsumption',
  accountTotalPriceForConsumption = 'AccountTotalPriceForConsumption',
  accountTotalEnergyProduction = 'AccountTotalEnergyProduction',
  accountTotalPriceForProduction = 'AccountTotalPriceForProduction',
}
