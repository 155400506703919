import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { ExnatonService } from '../exnaton.service';
import { ExnatonGroupType, ExnatonMoleculeField, ExnatonMoleculeType, ExnatonType } from './molecule-type.enum';
import { catchError, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface TitleHelper {
  content: string;
}

interface MissingData {
  titleHelper: TitleHelper;
}

interface Production {
  production: boolean;
}

export interface TipItem {
  title: string;
  content: string;
}

@Component({
  selector: 'app-exnaton-molecule',
  templateUrl: './exnaton-molecule.component.html',
  styleUrls: ['./exnaton-molecule.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ExnatonService],
})
export class ExnatonMoleculeComponent implements OnInit {
  @Input({ required: true }) exnatonType: ExnatonType;
  @Input() exnatonField: ExnatonMoleculeField;
  @Input() set showProduction(showProduction: boolean) {
    this.production = { production: showProduction };
  }
  @Input() set tooltip(tooltip: string) {
    this.titleHelper = { content: tooltip };
    this.missingData = { titleHelper: this.titleHelper };
  }
  @Input() set tipItems(tipItems: TipItem[]) {
    this.items = tipItems;
  }

  scriptLoaded: boolean | null = null; // null = init state, true = loaded, false = error
  titleHelper: TitleHelper;
  missingData: MissingData;
  production: Production;
  items: TipItem[];

  exnatonMoleculeType = ExnatonMoleculeType;
  exnatonGroupType = ExnatonGroupType;
  exnatonMoleculeField = ExnatonMoleculeField;

  constructor(private exnatonService: ExnatonService, private translateService: TranslateService) {
    (window as any).exnatonLoadingCallback = () => {
      // TODO: implement callback methods
    };
  }

  ngOnInit(): void {
    this.exnatonService.scriptLoaded
      .pipe(catchError(() => of(false)))
      .subscribe((isScriptLoaded: boolean | undefined) => {
        this.scriptLoaded = isScriptLoaded || false;
      });
  }
}
