import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { ExnatonMoleculeComponent } from '@app/modules/customer-zone/exnaton/exnaton-molecule/exnaton-molecule.component';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { VersionSize } from '@app/shared/models/units.interface';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { SitesSwitcherComponent } from '@app/shared/components/sites-switcher/sites-switcher.component';
import { Product } from '@app/modules/customer-zone/user/models/product.interface';
import {
  ExnatonMoleculeType,
  ExnatonGroupType,
  ExnatonMoleculeField,
} from '@app/modules/customer-zone/exnaton/exnaton-molecule/molecule-type.enum';
import { TipItem } from '../../../exnaton/exnaton-molecule/exnaton-molecule.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TooltipComponent } from '../../../../../shared/components/tooltip/tooltip.component';

@Component({
  selector: 'app-dynamic-tariff',
  templateUrl: './dynamic-tariff.component.html',
  styleUrls: ['./dynamic-tariff.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe, TranslateModule, SitesSwitcherComponent, ExnatonMoleculeComponent, TooltipComponent],
})
export class DynamicTariffComponent implements OnInit {
  sites$: Observable<Site[]>;
  activeSite: Site;
  activeReference: string;
  activeSiteId: string;
  notifier = new Subject<void>();
  accessDynamicTariff = false;
  accessInjection = false;
  tipItems: TipItem[] = [];

  versionSize = VersionSize;
  exnatonMoleculeType = ExnatonMoleculeType;
  exnatonGroupType = ExnatonGroupType;
  exnatonMoleculeField = ExnatonMoleculeField;

  constructor(private facade: MainFacade, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.initTips();

    this.facade.utils.setPageTitle('pages.meterReading.pageTitle');
    this.facade.activeSite$
      .pipe(
        filter((site: Site) => !!site),
        takeUntil(this.notifier)
      )
      .subscribe((site: Site) => {
        this.activeReference = this.facade.state$.value.reference;
        this.sites$ = this.facade.sites$;
        this.activeSiteId = site?.id;
        this.activeSite = site;

        this.accessDynamicTariff = false;
        site.products.forEach((product: Product) => {
          const accesRightByEan = this.facade.state$.value.accessRights?.sites?.[site.id]?.eans?.[product.ean];
          if (accesRightByEan?.accessDynamicTariffFeatures) {
            this.accessDynamicTariff = true;
          }
          if (accesRightByEan?.injector) {
            this.accessInjection = true;
          }
        });
      });

    this.translateService.onLangChange.pipe(takeUntil(this.notifier)).subscribe(() => {
      this.initTips();
    });
  }

  public switchSite(siteId: string) {
    this.facade.updateActiveSite(siteId);
  }

  private initTips() {
    this.tipItems = [];

    for (let index = 1; index <= 8; index++) {
      this.tipItems.push({
        title: this.translateService.instant(`pages.dynamicTariff.tip.tip${index}.title`),
        content: this.translateService.instant(`pages.dynamicTariff.tip.tip${index}.content`),
      });
    }
  }
}

_('pages.dynamicTariff.tip.tip1.title');
_('pages.dynamicTariff.tip.tip2.title');
_('pages.dynamicTariff.tip.tip3.title');
_('pages.dynamicTariff.tip.tip4.title');
_('pages.dynamicTariff.tip.tip5.title');
_('pages.dynamicTariff.tip.tip6.title');
_('pages.dynamicTariff.tip.tip7.title');
_('pages.dynamicTariff.tip.tip8.title');
_('pages.dynamicTariff.tip.tip9.title');
_('pages.dynamicTariff.tip.tip1.content');
_('pages.dynamicTariff.tip.tip2.content');
_('pages.dynamicTariff.tip.tip3.content');
_('pages.dynamicTariff.tip.tip4.content');
_('pages.dynamicTariff.tip.tip5.content');
_('pages.dynamicTariff.tip.tip6.content');
_('pages.dynamicTariff.tip.tip7.content');
_('pages.dynamicTariff.tip.tip8.content');
_('pages.dynamicTariff.tip.tip9.content');
